import { DOMParser } from 'prosemirror-model';

import { type DocNode } from '@atlaskit/adf-schema';
import { defaultSchema } from '@atlaskit/adf-schema/schema-default';
import { JSONTransformer } from '@atlaskit/editor-json-transformer';

const adfTransformer = new JSONTransformer();

/**
 * This parser will take HTML and convert it to ADF
 * This includes rich text elements like mentions, smartlinks, tables etc
 *
 * @param html
 * @returns DocNode
 */
export const htmlToADF = (html: string): DocNode => {
	const element = document.createElement('div');
	element.innerHTML = html;

	const doc = DOMParser.fromSchema(defaultSchema).parse(element);
	return adfTransformer.encode(doc) as DocNode;
};
