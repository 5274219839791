import { doc, p } from '@atlaskit/adf-utils/builders';

import { htmlToADF } from '@atlassian/html-adf-transformer';
import { type ContentType } from '@atlassian/ai-issue-create';

import type {
	InsertStorageFragmentRequestPayload,
	IssueCreateSidePanelActions,
} from '@confluence/issue-create-side-panel';
import { fg } from '@confluence/feature-gating';
import {
	BULK_ISSUE_CREATE_SIDE_PANEL_ID,
	BULK_ISSUE_CREATE_SIDE_PANEL_WIDTH,
	ISSUE_CREATE_SIDE_PANEL_ID,
	ISSUE_CREATE_SIDE_PANEL_WIDTH,
	getValidRange,
} from '@confluence/issue-create-side-panel';
import type { RightSidebarContextType } from '@confluence/page-layout-context';

import { convertSelectionToLegacyFormat } from './convertSelectionToLegacyFormat';

const getHTMLString = (activeSelection: Selection | null | undefined): string => {
	if (!activeSelection) {
		return '';
	}

	const docFragment = activeSelection.getRangeAt(0).cloneContents();
	const tempDiv = document.createElement('div');
	tempDiv.appendChild(docFragment);

	return tempDiv.innerHTML;
};

export const SUMMARY_MAX_LENGTH = 254;

export const splitText = (text: string, maxLength: number): [string, string, string] => {
	const horizontalEllipsis = '…';
	if (text.length <= maxLength) {
		return [text, '', ''];
	}
	// Calculate the length of the first part without including the ellipsis
	const firstPartLength = maxLength - horizontalEllipsis.length;
	// Split the text into three parts
	const firstPart = text.substring(0, firstPartLength);
	const firstPartWithHorizontalEllipis = firstPart + horizontalEllipsis;
	const secondPart = text.substring(firstPartLength);
	return [firstPartWithHorizontalEllipis, firstPart, secondPart];
};

const getLastFetchTimeFromMetaTag = (): number | null => {
	const metaTag = document.querySelector(`meta[name="confluence-request-time"]`);
	return metaTag ? Number(metaTag.getAttribute('content')) : null;
};

const constructInsertStorageFragmentRequestPayload = (
	range,
	contentId,
	contentRef,
	lastModified?,
) => {
	const {
		searchText: { numMatches, index: matchIndex, selectedText },
	} = convertSelectionToLegacyFormat(contentRef, range, contentId);

	return {
		pageId: contentId,
		selectedText,
		index: matchIndex,
		numMatches,
		// Get last fetch time of current page.
		// This used to be the request time, but this caused problems due to time drift between the server and database.
		// So now try to use the content last modification date on load if that exists, then fallback onto the
		// request time if it's not available.
		lastFetchTime: lastModified ? new Date(lastModified).getTime() : getLastFetchTimeFromMetaTag(),
		xmlModification: undefined,
	} as InsertStorageFragmentRequestPayload;
};

export const getSummaryAndDescription = (
	activeSelection: Selection | null | undefined,
): [string, string, string] => {
	const selectionString = activeSelection?.toString() || '';

	const [summary, descriptionPt1, descriptionPt2] = splitText(selectionString, SUMMARY_MAX_LENGTH);
	/*
		   The regex matches one or more occurrences of any line break (\r, \n) or tab (\t)
		   and replaces them all with a single space. (We only consider whitespace characters because
		   these are the only ones that ultimately stick when parsing the summary.)
		*/
	const summaryWithoutFormatting = summary.replace(/[\r\n\t]+/g, ' ');

	return [summaryWithoutFormatting, descriptionPt1, descriptionPt2];
};

export interface CreateSingleJiraIssueHandleParams {
	rightSidebarContext: RightSidebarContextType;
	actions: IssueCreateSidePanelActions;
	activeSelection?: Selection | null;
	contentId: string;
	container: HTMLElement;
	lastModified?: string | null;
	isCreatedWithAi?: boolean;
}

export interface CreateSingleAiJiraIssueHandleParams {
	rightSidebarContext: RightSidebarContextType;
	actions: IssueCreateSidePanelActions;
	activeSelection?: Selection | null;
	contentId: string;
	contentType?: ContentType;
	container: HTMLElement;
	fireAiAnalytics: () => void;
	lastModified?: string | null;
	pressTracing?: (timeStamp?: number) => void;
}

export interface CreateBulkJiraIssuesHandleParams {
	rightSidebarContext: RightSidebarContextType;
	pressTracing?: (timeStamp?: number) => void;
}

export const createSingleJiraIssueHandle = ({
	rightSidebarContext,
	actions,
	activeSelection,
	contentId,
	container,
	lastModified,
}: CreateSingleJiraIssueHandleParams) => {
	const insertStorageFragmentRequestPayload = constructInsertStorageFragmentRequestPayload(
		fg('confluence_issue_create_new_insertion_flow')
			? getValidRange(activeSelection?.getRangeAt(0))
			: activeSelection?.getRangeAt(0),
		contentId,
		container,
		lastModified,
	);
	const [summary, descriptionPt1, descriptionPt2] = getSummaryAndDescription(activeSelection);

	/* eslint-disable @typescript-eslint/no-floating-promises */
	actions.setSummary(summary);
	actions.setDescriptionAdf(doc(p(descriptionPt1 + descriptionPt2)));
	actions.setInsertStorageFragmentRequestPayload(insertStorageFragmentRequestPayload);
	actions.setIsCreatedWithAi(false);
	/* eslint-enable @typescript-eslint/no-floating-promises */

	rightSidebarContext.open(ISSUE_CREATE_SIDE_PANEL_ID, ISSUE_CREATE_SIDE_PANEL_WIDTH);
};

export const createSingleAiJiraIssueHandle = ({
	rightSidebarContext,
	actions,
	activeSelection,
	contentId,
	contentType,
	container,
	fireAiAnalytics,
	lastModified,
	pressTracing,
}: CreateSingleAiJiraIssueHandleParams) => {
	fireAiAnalytics();
	const highlightedText = fg('confluence_ai_highlighted_text_using_adf')
		? htmlToADF(getHTMLString(activeSelection))
		: doc(p(activeSelection?.toString() || ''));

	const insertStorageFragmentRequestPayload = constructInsertStorageFragmentRequestPayload(
		fg('confluence_issue_create_new_insertion_flow')
			? getValidRange(activeSelection?.getRangeAt(0))
			: activeSelection?.getRangeAt(0),
		contentId,
		container,
		lastModified,
	);
	const [summary, descriptionPt1, descriptionPt2] = getSummaryAndDescription(activeSelection);

	/* eslint-disable @typescript-eslint/no-floating-promises */
	actions.setSummary(summary);
	actions.setDescriptionAdf(doc(p(descriptionPt1 + descriptionPt2)));
	actions.setContentId(contentId);
	contentType && actions.setContentType(contentType);
	actions.setHighlightedText(highlightedText);
	actions.setInsertStorageFragmentRequestPayload(insertStorageFragmentRequestPayload);
	actions.setIsCreatedWithAi(true);
	/* eslint-enable @typescript-eslint/no-floating-promises */

	rightSidebarContext.open(ISSUE_CREATE_SIDE_PANEL_ID, ISSUE_CREATE_SIDE_PANEL_WIDTH);
	pressTracing?.();
};

export const createMultipleJiraIssuesHandleModernized = ({
	rightSidebarContext,
	pressTracing,
}: CreateBulkJiraIssuesHandleParams) => {
	rightSidebarContext.open(BULK_ISSUE_CREATE_SIDE_PANEL_ID, BULK_ISSUE_CREATE_SIDE_PANEL_WIDTH);
	pressTracing?.();
};
