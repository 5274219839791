import { LoadableLazy } from '@confluence/loadable';

export const ReadingAidsChangeboardingModal = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ReadingAidsChangeboardingModal" */ '../src/ReadingAidsChangeboardingModal'
			)
		).ReadingAidsChangeboardingModal,
});
