import { fg } from '@confluence/feature-gating';

import { useIsAIIssueCreationAllowed } from './useIsAIIssueCreationAllowed';
import { getIsCreateIssueWithAiEnabledByUser } from './localStorageHelpers';

export const useIsAIIssueCreationEnabled = () => {
	const isAIIssueCreationAllowed = useIsAIIssueCreationAllowed();
	const isCreateIssueWithAiEnabledByUser = getIsCreateIssueWithAiEnabledByUser();

	if (fg('confluence_single_issue_create_ai_toggle')) {
		return isAIIssueCreationAllowed && isCreateIssueWithAiEnabledByUser;
	}

	return isAIIssueCreationAllowed;
};
