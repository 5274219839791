import React, { useCallback, useEffect, useState, useRef, type FC } from 'react';
import { useIntl, defineMessages } from 'react-intl-next';

import { Popper } from '@atlaskit/popper';
import { SpotlightPulse, SpotlightCard } from '@atlaskit/onboarding';
import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';

import { useCoordinationClient } from '@confluence/engagement-provider';

const i18n = defineMessages({
	spotlightAction: {
		id: 'highlight-actions.create-inline-comment.inline-comment-on-inline-node-spotlight.spotlight-action',
		defaultMessage: 'Got it',
		description: 'Text for the button to dismiss the spotlight',
	},
	spotlightContent: {
		id: 'highlight-actions.create-inline-comment.inline-comment-on-inline-node-spotlight.spotlight-content',
		defaultMessage:
			'You can now leave comments on inline elements like links, dates, statuses and mentions.',
		description: 'Text for the content of the spotlight',
	},
});

/*
 * Engagement Platform message ID for the inline comment on inline node spotlight
 * prod: https://engage-api.prod.atl-paas.net/#/messages/inline-comments-on-inline-node-spotlight
 * stg:  https://engage-api.staging.atl-paas.net/#/messages/inline-comments-on-inline-node-spotlight
 */
export const INLINE_COMMENT_ON_INLINE_NODE_SPOTLIGHT_MESSAGE_ID =
	'inline-comments-on-inline-node-spotlight';

export const INLINE_COMMENT_ON_INLINE_NODE_SPOTLIGHT_CARD_ID =
	'inline-comments-on-inline-node-spotlight-card-id';

export interface InlineCommentOnInlineNodeSpotlightContainerProps {
	children: React.ReactNode;
}

let startMessageCache: Promise<boolean> | undefined = undefined;

export const InlineCommentOnInlineNodeSpotlightContainer: FC<
	InlineCommentOnInlineNodeSpotlightContainerProps
> = ({ children }) => {
	const coordinationClient = useCoordinationClient();
	const { formatMessage } = useIntl();
	const [isSpotlightOn, setIsSpotlightOn] = useState(false);
	// Ref to keep track of spotlight status while the component is unmounted
	// This is to avoid using a staled isSpotlightOn state when the component is unmounted
	const spotlightStatusRef = useRef(false);

	const loadEnagementPlatformMessageStatus = async () => {
		try {
			if (!startMessageCache) {
				startMessageCache = coordinationClient.start(
					INLINE_COMMENT_ON_INLINE_NODE_SPOTLIGHT_MESSAGE_ID,
				);
			}
			const isStarted = await startMessageCache;

			// only fire exposure event when the target user is in audience
			if (
				isStarted &&
				editorExperiment('comment_on_inline_node_spotlight', true, { exposure: true })
			) {
				setIsSpotlightOn(true);
				spotlightStatusRef.current = true;
			}
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(
				`Unable to start flow message ${INLINE_COMMENT_ON_INLINE_NODE_SPOTLIGHT_MESSAGE_ID}. ${err}`,
			);
		} finally {
			startMessageCache = undefined;
		}
	};

	const stopMessage = useCallback(async () => {
		try {
			setIsSpotlightOn(false);
			spotlightStatusRef.current = false;
			await coordinationClient.stop(INLINE_COMMENT_ON_INLINE_NODE_SPOTLIGHT_MESSAGE_ID);
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(
				`Unable to start flow message ${INLINE_COMMENT_ON_INLINE_NODE_SPOTLIGHT_MESSAGE_ID}. ${err}`,
			);
		}
	}, [coordinationClient]);

	useEffect(() => {
		void loadEnagementPlatformMessageStatus();

		return () => {
			if (spotlightStatusRef.current) {
				void stopMessage();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [spotlightReferenceElement, setSpotlightReferenceElement] = useState<HTMLElement | null>(
		null,
	);

	if (!isSpotlightOn) {
		return <>{children}</>;
	}

	return (
		<>
			<SpotlightPulse radius={3} pulse={isSpotlightOn}>
				<div ref={(element) => setSpotlightReferenceElement(element)}>{children}</div>
			</SpotlightPulse>
			{spotlightReferenceElement && (
				<Popper
					referenceElement={spotlightReferenceElement}
					placement="top-start"
					strategy="absolute"
				>
					{({ ref, style }) => (
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
						<div ref={ref} style={style} id={INLINE_COMMENT_ON_INLINE_NODE_SPOTLIGHT_CARD_ID}>
							<SpotlightCard
								testId="highlight-actions-create-inline-comment-spotlight"
								actions={[
									{
										onClick: stopMessage,
										text: formatMessage(i18n.spotlightAction),
									},
								]}
								width={275}
							>
								{formatMessage(i18n.spotlightContent)}
							</SpotlightCard>
						</div>
					)}
				</Popper>
			)}
		</>
	);
};
