import gql from 'graphql-tag';

import { ContentOperationsFragment } from '@confluence/entity-operations/entry-points/EntityOperationsFragment.fragment';

export const PermissionCheckAddCommentQuery = gql`
	query PermissionCheckAddCommentQuery($contentId: ID!) {
		content(id: $contentId, status: ["draft", "current"]) {
			nodes {
				...ContentOperationsFragment
			}
		}
	}
	${ContentOperationsFragment}
`;
