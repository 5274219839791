import { useQuery } from '@apollo/react-hooks';

import { PermissionCheckAddCommentQuery } from './graphql/PermissionCheckAddCommentQuery.graphql';
import type {
	PermissionCheckAddCommentQuery as PermissionCheckAddCommentQueryType,
	PermissionCheckAddCommentQueryVariables,
} from './graphql/__types__/PermissionCheckAddCommentQuery';

export const useAddCommentPermissionCheck = (contentId: string) => {
	const { data, loading, error, refetch, networkStatus } = useQuery<
		PermissionCheckAddCommentQueryType,
		PermissionCheckAddCommentQueryVariables
	>(PermissionCheckAddCommentQuery, {
		variables: {
			contentId,
		},
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'cache-and-network',
	});

	const contentNode = data?.content?.nodes?.[0];
	const operations = contentNode?.operations ?? [];

	const createPermission = operations.some(
		(operationResults) =>
			operationResults?.operation === 'create' && operationResults?.targetType === 'comment',
	);

	return {
		canAddComments: createPermission,
		loading,
		contentQueryError: error,
		refetch,
		networkStatus,
	};
};
