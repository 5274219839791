import { LoadableLazy } from '@confluence/loadable';

export const ReadingAidsChangeboardingSpotlight = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ReadingAidsChangeboardingSpotlight" */ '../src/ReadingAidsChangeboardingSpotlight'
			)
		).ReadingAidsChangeboardingSpotlight,
});

export { READING_AIDS_CHANGEBOARDING_SPOTLIGHT_ID } from '../src/ReadingAidsChangeboardingSpotlight';
