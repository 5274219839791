import { createContext } from 'react';

export type HighlightActionsContextState = {
	firstHighlightShown: boolean;
};

const defaultState: HighlightActionsContextState = {
	firstHighlightShown: false,
};

export const HighlightActionsContext = createContext(defaultState);

HighlightActionsContext.displayName = 'HighlightActionsContext';
