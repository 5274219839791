import React from 'react';

type ContentBodyContextValueType = {
	lastModificationDate: string | null | undefined;
};

export const ContentBodyContext = React.createContext<ContentBodyContextValueType>({
	lastModificationDate: null,
});

ContentBodyContext.displayName = 'ContentBodyContext';
